<template>
  <iframe
    onload="this.contentWindow.focus()"
    id="knips-frame"
    :src="`https://knips.app/feed/BsZn3hkNczJ4R7RO5j73?hideMenu=true&project=${item.name}`"
    frameborder="0"
    style="height: 100vh; width: 100%;"
  >
  </iframe>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  computed: {
    ...mapState('Project', ['item']),
  },
  methods: {
    ...mapActions('Project', ['fetch']),
  },
  mounted() {
    this.$el.contentWindow.document.body.focus()
  },
  created() {
    this.fetch()
  },
  metaInfo() {
    return {
      title: 'News',
    }
  },
}
</script>
