<template>
  <Residentials :filter="filter" :project="project" :properties="properties" />
</template>

<script>
import Residentials from '@kvass/residentials/src/Component'
import API from '@/api'
import Bus from '@/bus'
import { GetRouteBasedValue } from '@/utils'

export default {
  data() {
    return {
      filter: {},
      project: API.project,
    }
  },
  computed: {
    properties: GetRouteBasedValue({
      Project: 'Project.item.metadata.siteSettings.featuredResidentialProperties',
      Residential: 'Residential.item.project.metadata.siteSettings.featuredResidentialProperties',
      default: [],
    }),
  },
  created() {
    Bus.on('residentials:filter', filter => (this.filter = filter))
  },
  components: {
    Residentials,
  },
}
</script>

<style lang="scss">
.residentials {
  &__list {
    @include respond-below('phone') {
      font-size: 0.7em;
    }
  }
}
</style>
