<template>
  <Gallery :value="items" scroll-anchor="floorplan" class="residential-floorplan" size="contain" />
</template>

<script>
import { mapState } from 'vuex'
import Gallery from '@/components/Gallery'

export default {
  computed: {
    ...mapState('Residential', ['item']),
    items() {
      return this.$path('item.media.floorplan') || []
    },
  },
  components: {
    Gallery,
  },
}
</script>

<style lang="scss">
.residential-floorplan {
  height: 80vh;
}
</style>
