<template>
  <Masonry :value="items" class="residential-masonry" />
</template>

<script>
import { mapState } from 'vuex'
import Masonry from '@/components/Masonry'

export default {
  computed: {
    ...mapState('Residential', ['item']),
    items() {
      return this.$path('item.media.gallery') || []
    },
  },
  components: {
    Masonry,
  },
}
</script>

<style lang="scss">
.residential-masonry {
}
</style>
