<template>
  <div
    id="app"
    :class="['route--' + view.toLowerCase(), isOnTop ? 'page--is-top' : 'page--is-scrolled']"
  >
    <div
      v-if="$route.meta.navigation !== false"
      class="nav"
      :class="[
        'nav--' + view.toLowerCase(),
        isOnTop ? 'page--is-top' : 'page--is-scrolled',
        { ['nav--' + view.toLowerCase() + '-expanded']: isExpanded },
      ]"
    >
      <div class="container">
        <NavigationComponent
          :logo="logo"
          :items="items"
          :title="projectName"
          padding="20px 0"
          action="https://www.mittegetlokale.no/"
          :isOpen.sync="isExpanded"
        />
      </div>
    </div>
    <router-view />
    <FooterComponent v-if="$route.meta.footer !== false" :navigation="items" />
    <AssetComponent />
  </div>
</template>

<script>
import { NavigationComponent } from 'vue-elder-navigation'
import AssetComponent, { EventBus as AssetBus } from '@kvass/asset-manager'
import FooterComponent from '@/components/Footer'
import API from './api'
import BrowserApiMixin from '@/mixins/browser-api'
import { GetRouteBasedValue } from '@/utils'
import { Limit } from '@/filters'
import i18nCustom from '@/i18nCustom'

export default {
  mixins: [BrowserApiMixin('app')],
  data() {
    return {
      isOnTop: true,
      customItems: [],
      isExpanded: false,
    }
  },
  computed: {
    logos() {
      return {
        light: API.logoInverted,
        dark: API.logo,
      }
    },
    isSingular() {
      return API.projectType === 'singular'
    },
    view() {
      if (!this.isSingular) return this.$path('name', this.$route) || ''
      return 'ProjectResidential'
    },
    logo() {
      if (this.$route.name === 'Project' && !this.isSingular)
        return this.isOnTop ? this.logos.light : this.logos.dark
      return this.logos.dark
    },

    labels: GetRouteBasedValue({
      Project: 'Project.item.metadata.labels',
      Residential: 'Residential.item.project.metadata.labels',
      default: {},
    }),
    projectName: GetRouteBasedValue({
      Project: 'Project.item.name',
      Residential: 'Residential.item.project.name',
      default: '',
    }),
    seo: GetRouteBasedValue({
      Project: 'Project.item.customFields.seo',
      Residential: 'Residential.item.project.customFields.seo',
      default: '',
    }),
    hasFlatfinder: GetRouteBasedValue({
      Project: 'Project.item.flatfinders.length',
      Residential: 'Residential.item.project.flatfinders.length',
      default: false,
    }),
    hasResidentials() {
      return this.$path('$store.state.Residentials.item.docs.length')
    },
    hasAttachments: GetRouteBasedValue({
      Project() {
        let path = !this.isSingular
          ? 'Project.item.media.attachments.length'
          : [
              'Residential.item.media.attachments.length',
              'Residential.item.project.media.attachments.length',
            ]

        if (path instanceof Array) return path.map(item => this.$path(item, this.$store.state))
        return this.$path(path, this.$store.state)
      },

      Residential: [
        'Residential.item.media.attachments.length',
        'Residential.item.project.media.attachments.length',
      ],
      default: false,
    }),

    brochure: GetRouteBasedValue({
      Project() {
        let path = !this.isSingular
          ? 'Project.item.media.brochure'
          : 'Residential.item.project.media.brochure'

        return this.$path(path, this.$store.state)
      },
      Residential: 'Residential.item.project.media.brochure',
      default: [],
    }),
    brochureStrategy: GetRouteBasedValue({
      Project() {
        let path = !this.isSingular
          ? 'Project.item.metadata.siteSettings.brochureDownloadStrategy'
          : 'Residential.item.project.metadata.siteSettings.brochureDownloadStrategy'
        return this.$path(path, this.$store.state)
      },
      Residential: 'Residential.item.project.metadata.siteSettings.brochureDownloadStrategy',
      default: 'direct',
    }),
    gallery: GetRouteBasedValue({
      Project() {
        let path = !this.isSingular
          ? 'Project.item.media.gallery'
          : 'Residential.item.media.gallery'
        return this.$path(path, this.$store.state)
      },
      Residential: 'Residential.item.media.gallery',
      default: [],
    }),
    items() {
      return [...this.defaultItems, ...this.customItems].sort((a, b) => a.order - b.order)
    },
    defaultItems() {
      return [
        {
          label: this.$t('theProject'),
          action: this.redirect('Project', { scrollTo: 0 }),
          condition: !this.isSingular,
        },
        {
          label: this.labels.flatfinder || this.$tc('flatfinder'),
          exact: true,
          action: this.redirect(['Project', 'Residential'], { hash: '#flatfinder' }),
          condition: this.hasFlatfinder,
        },
        {
          label: this.labels.residentialList || this.$tc('residential', 2),
          exact: true,
          action: this.redirect(['Project', 'Residential'], { hash: '#residentials' }),
          condition: !this.hasFlatfinder && this.hasResidentials,
        },

        {
          label: this.$tc('image', 2),
          action: this.redirect(['Project', 'Residential'], {
            hash: '#gallery',
          }),
          condition: this.gallery.length,
        },
        {
          label: this.$t('map'),
          action: this.redirect(['Project', 'Residential'], {
            hash: '#map',
          }),
        },
        {
          label: this.i18nCustom('news'),
          action: this.knipsFeed,
          condition: !['News'].includes(this.$route.name),
        },
        {
          label: this.$t('brochure'),
          exact: true,
          action: () => {
            if (this.brochure.length > 1) return
            AssetBus.emit('download', {
              type: 'brochure',
              strategy: this.brochureStrategy,
              asset: this.brochure[0],
            })
          },
          items:
            this.brochure.length > 1
              ? this.brochure.map(b => ({
                  label: Limit(b.name, 30),
                  action: () => {
                    AssetBus.emit('download', {
                      type: 'brochure',
                      strategy: this.brochureStrategy,
                      asset: b,
                    })
                  },
                  icon: ['fad', 'file-pdf'],
                }))
              : undefined,
          condition: this.brochure.length,
        },
        {
          label: this.$tc('attachment', 2),
          action: this.redirect(['Project', 'Residential'], {
            hash: '#attachments',
          }),
          condition: this.hasAttachments,
        },
        {
          label: this.$t('leadFormTitle'),
          class: 'elder__navigation-component--primary',
          action: this.redirect(['Project', 'Residential'], {
            hash: '#lead',
          }),
        },
      ]
        .filter(x => !('condition' in x) || x.condition)
        .filter(x => x.action || (x.items && x.items.length))

        .map((i, index) => ({ ...i, order: (index + 1) * 10 }))
    },
  },
  methods: {
    i18nCustom,
    redirect(name, options) {
      return async function() {
        name = name instanceof Array ? name : [name]

        let { action, scrollTo, hash } = options

        if (!name.includes(this.$route.name)) await this.$router.push({ name: name[0], hash })
        else if (hash) {
          let target = document.querySelector(
            '.scroll-anchor--type-anchor.scroll-anchor--value-' + hash.substring(1),
          )
          if (!target) return
          target.scrollIntoView({ behavior: 'smooth' })
        }
        if (action) return this.action()
        if (scrollTo !== undefined) window.scrollTo(0, scrollTo)
      }
    },
    knipsFeed() {
      switch (this.$route.name) {
        case 'News':
          return window.scrollTo(0, 0)
        default:
          return this.$router.push({ name: 'News' })
      }
    },
  },
  created() {
    const debounce = fn => {
      let frame
      return (...params) => {
        if (frame) cancelAnimationFrame(frame)
        frame = requestAnimationFrame(() => fn(...params))
      }
    }

    const storeScroll = () => {
      this.isOnTop = window.scrollY <= 5
    }

    document.addEventListener('scroll', debounce(storeScroll), { passive: true })
    storeScroll()
  },
  metaInfo() {
    return {
      titleTemplate: this.projectName
        ? `%s | ${this.$path('seo.title') || `Lagerlokale ${this.projectName} - mittegetlokale.no`}`
        : `${this.$t('loading', { resource: null })}...`,
      link: [
        { rel: 'preload', href: this.logos.dark, as: 'image' },
        { rel: 'preload', href: this.logos.light, as: 'image' },
        { rel: 'icon', href: API.favicon, type: 'image/png' },
      ],
    }
  },
  components: {
    NavigationComponent,
    FooterComponent,
    AssetComponent,
  },
}
</script>

<style lang="scss">
@import '@/styles/main';

.nav {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: get-layer('nav');
  background: white;
  box-shadow: 0 10px 15px -10px rgba(black, 0.05);

  transition: background 150ms ease-in;

  .elder__navigation-logo-image {
    width: 100%;
  }

  @include respond-below('phone') {
    .elder__navigation-logo-image {
      max-height: 45px !important;
      max-width: 30vw;
    }
  }

  .route--project.page--is-scrolled & {
    position: fixed;
    background: white;
    color: $dark;
    box-shadow: 0 10px 15px -10px rgba(black, 0.05);

    .elder__navigation-component,
    .elder__navigation-bars {
      color: var(--vue-elder-text-color, #444);
    }
  }

  .elder__navigation-dropdown .elder-dropdown__content .elder__navigation-component {
    color: var(--vue-elder-text-color, #444) !important;
  }

  .elder__navigation-component {
    &.elder__navigation-component--primary {
      background-color: var(--custom-color) !important;
      color: white !important;
    }
  }
  .elder__navigation-component {
    font-weight: 400;
  }

  &--project {
    position: absolute;
    background: linear-gradient(to bottom, black -200%, transparent 100%);
    box-shadow: none;

    .elder__navigation {
      &-bars {
        color: white;
      }
      &-component {
        color: white;
      }
    }
    &-expanded {
      box-shadow: none;
      background: linear-gradient(180deg, #000 -200%, #00000036);
      backdrop-filter: blur(5px);
      color: white;
      padding-bottom: 2rem;
    }
  }
}
</style>
