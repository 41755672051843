<template>
  <ResidentialTemplates
    v-if="$path('references', value)"
    :value="value"
    class="residential-templates"
  />
</template>

<script>
import { Items as ResidentialTemplates } from '@kvass/template-residential-template'
import { GetRouteBasedValue, GetCustomField } from '@/utils'

export default {
  computed: {
    customFields: GetRouteBasedValue({
      Project: 'Project.item.customFields',
      default: [],
    }),
    value() {
      return GetCustomField('residential-templates', this.customFields) || []
    },
  },
  components: {
    ResidentialTemplates,
  },
}
</script>

<style lang="scss">
.residential-templates {
}
</style>
