<template>
  <div class="project-hero">
    <div class="project-hero__overlay">
      <div class="project-hero__overlay-content" :style="style">
        <div class="project-hero__overlay-slogan" v-if="slogan" v-html="slogan"></div>
        <slot name="overlay" />
      </div>
    </div>
    <div class="project-hero__slide">
      <Slider v-if="items.length" :value="items" display-thumbnail />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { ScrollIntoView, GetCustomField } from '@/utils'
import Slider from '@kvass/media-render/Slider'
import Lead from '@kvass/lead'
import Properties from './Properties'

export default {
  computed: {
    ...mapState('Project', ['item']),
    items() {
      return this.$path('item.media.cover') || []
    },
    slogan() {
      return GetCustomField('slogan', this.item.customFields)
    },
    style() {
      let ratio = navigator.userAgent.includes('Windows') ? window.devicePixelRatio : 1

      return {
        '--pixel-ratio': ratio,
        'padding-top': ratio > 1 ? '1rem' : '6rem',
        'padding-bottom': ratio > 1 ? '1rem' : '4rem',
      }
    },
  },
  methods: {
    gotoInfo() {
      ScrollIntoView('.section__info', -document.querySelector('.nav').clientHeight)
    },
  },
  metaInfo() {
    return {
      link: [...this.items.map(item => ({ rel: 'preload', as: 'image', href: item }))],
    }
  },
  components: {
    Slider,
    Lead,
    Properties,
  },
}
</script>

<style lang="scss">
.project-hero {
  $height: 105vh;

  position: relative;
  min-height: $height;

  background-color: css-alpha('primary', 0.1);

  display: flex;
  flex-direction: column;

  &__overlay {
    background-color: css-alpha('primary', 0.5);
    z-index: 1;

    width: 100%;
    min-height: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &-content {
      z-index: 2;
      gap: 2.5rem;

      width: 100%;
      height: inherit;
      display: flex;
      transform: scale(calc(1 / var(--pixel-ratio)));

      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;

      @include respond-below('phone') {
        gap: 1.5rem;
        font-size: 0.9em;
        h2 {
          font-size: 1.1em;
        }
      }
    }

    &-slogan {
      color: white;
      font-size: 2.5em;

      h2 {
        font-size: 2em;
        margin-bottom: 0;
      }
      p {
        margin: 0;
      }

      @include respond-below('phone') {
        font-size: 1.3em;
        h2 {
          font-size: 1.9em;
        }
      }
    }
  }

  &__slide {
    position: absolute;
    flex-grow: 1;
    width: 100%;
    height: 100%;
  }
  .kvass-media-render-slider {
    width: 100%;
  }
}
</style>
