<template>
  <div class="masonry">
    <div class="masonry__content" :class="['masonry__content--length-' + value.length]">
      <div v-for="(item, index) in valueComp" :key="index" class="masonry__item">
        <component
          :is="item.Component"
          class="content"
          :value="item"
          ref="items"
          loop
          :mute="false"
        />
      </div>
    </div>
    <div :id="scrollAnchor" class="scroll-anchor"></div>
  </div>
</template>

<script>
import MediumZoom from 'medium-zoom'
import { Types } from '@kvass/media-render'

export default {
  props: {
    value: Array,
    scrollAnchor: {
      type: String,
      default: 'images',
    },
  },
  watch: {
    valueComp: {
      handler: 'attach',
      immediate: true,
    },
  },
  data() {
    return {
      zoomer: MediumZoom([], {
        scrollOffset: 20,
        background: 'rgba(255, 255, 255, .8)',
      }),
    }
  },
  computed: {
    valueComp() {
      return this.value.map(item => {
        return {
          ...item,
          Component: this.getComponent(item.type),
        }
      })
    },
  },
  methods: {
    getComponent(type) {
      switch (type) {
        case 'youtube':
          return Types.Youtube
        case 'vimeo':
          return Types.Vimeo
        default:
          return Types.Image
      }
    },
    attach() {
      this.detach()
      this.$nextTick(() => this.zoomer.attach(this.$refs.items.map(i => i.$el)))
    },
    detach() {
      if (!this.zoomer) return
      this.zoomer.detach()
    },
  },
  beforeDestroy() {
    this.detach()
  },
}
</script>

<style lang="scss">
.masonry {
  $gap: 3px;
  position: relative;
  padding: $gap;

  &__content {
    display: grid;
    grid-gap: $gap;
    grid-template-columns: repeat(3, 1fr);

    &--length-1 {
      grid-template-columns: 1fr;
    }

    &--length-2,
    &--length-4 {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 1600px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 900px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &__item {
    @include aspect-ratio(16, 9);
    width: 100%;
    height: 100%;
    z-index: 0;
    iframe {
      height: 100%;
      width: 100%;
    }
  }
}
</style>
