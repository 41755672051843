<template>
  <div v-if="items.length" class="project-properties">
    <template v-for="i in items">
      <div
        v-if="i.value"
        :key="i.key"
        class="project-properties__item"
        :class="{ 'project-properties__item-stats': i.type === 'stats' }"
      >
        <div class="project-properties__label">
          {{ i.label }}
        </div>
        <div class="project-properties__value">{{ i.value }}</div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import i18nCustom from '@/i18nCustom'
import { GetCustomField } from '@/utils'

const status = [{ key: 'sale' }, { key: 'sold' }, { key: 'reserved' }, { key: 'upcoming' }]
const resposiveItems = ['sizes', 'price-from', 'sold']
export default {
  data() {
    return {}
  },
  computed: {
    ...mapState('Project', ['item']),
    properties() {
      return this.$path('metadata.residentialProperties', this.item)
    },
    customProperties() {
      return GetCustomField('project-properties', this.item.customFields) || []
    },
    isResposive() {
      return window.innerWidth < 992
    },

    items() {
      return [
        {
          key: 'sizes',
          label: this.$t('sizes'),
          value: this.customProperties['sizes'] || this.getProperty('grossArea'),
        },
        {
          key: 'price-from',
          label: this.$t('priceFrom'),
          value:
            this.convertPrice(this.customProperties['price-from']) || this.getProperty('price'),
        },
        {
          key: 'completion',
          label: i18nCustom('completion'),
          value: this.customProperties['completion'],
        },
        ...status.map(i => {
          return {
            key: i.key,
            label: this.$t(`residentialStatus.${i.key}`),
            value: this.customProperties[i.key] || this.getStat(i.key),
            type: 'stats',
          }
        }),
      ].filter(item => {
        if (!this.isResposive) return item
        return resposiveItems.includes(item.key)
      })
    },

    stats() {
      return this.item.stats || {}
    },
  },
  methods: {
    getStat(key) {
      let keys = key instanceof Array ? key : [key]
      return keys.reduce((r, c) => r + this.stats[c], 0)
    },
    convertPrice(value) {
      if (!value) return

      return `${value
        .toLocaleString({
          minimumFractionDigits: 0,
        })
        .replace(new RegExp(',', 'g'), '.')},-`
    },

    getProperty(key) {
      if (!this.properties || !this.properties[key].length) return

      switch (key) {
        case 'price':
          return this.convertPrice(Math.min(...this.properties[key]))
        case 'grossArea':
          return `${Math.min(...this.properties[key])} - ${Math.max(...this.properties[key])}`
        default:
          return undefined
      }
    },
  },

  components: {},
}
</script>

<style lang="scss">
.project-properties {
  z-index: 1;
  max-width: 1400px;
  background-color: var(--primary);
  box-shadow: inset 0 10px 15px 0px #71717138;
  display: flex;
  flex-wrap: wrap;
  transform: translate(-50%, -50%);
  width: 100%;
  left: 50%;
  position: relative;
  align-items: center;
  justify-content: center;

  @include respond-below('phone') {
    bottom: 0;
    width: 100%;
    position: initial;
    transform: none;
  }

  &__item {
    flex-grow: 1;
    color: white;
    line-height: 1;
    display: flex;

    flex-direction: column;
    justify-content: center;
    gap: 0.75em;
    padding: 1.5rem;
    & > * {
      text-align: center;
    }

    @include respond-below('tablet') {
      width: 120px;
      flex-grow: 1;
      padding: 1.5rem 0.5rem;
    }

    &--coming {
      color: var(--custom-color);
      width: initial;
      padding: 1.5rem;
      font-size: 0.7em;

      & > * {
        margin: 0;
      }
    }
  }

  &__value {
    font-size: 1.2em;
    font-weight: bold;
    color: white;
    @include respond-below('phone') {
      font-size: 0.75em;
    }
  }

  &__label {
    font-weight: bold;
    color: var(--custom-color);
    font-size: 1em;

    @include respond-below('phone') {
      font-size: 0.7em;
    }
  }
}
</style>
