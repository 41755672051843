<template>
  <div class="gallery" :class="['gallery--size-' + size]">
    <div class="gallery__main" :key="current.url">
      <component
        class="gallery__main-image"
        :is="current.Component"
        :value="getValue(current)"
        loop
        :mute="false"
        ref="current"
        @mouseleave.native="start"
        @mouseenter.native="stop"
        @playing="playing = true"
        @ended="ended"
        size="contain"
      />

      <caption v-if="current.caption" class="gallery__caption">
        {{
          current.caption
        }}
      </caption>
      <a
        v-if="current.type == 'application/pdf'"
        :href="current.url"
        target="_blank"
        class="gallery__main-link"
      >
        <FontAwesomeIcon :icon="['far', 'external-link-alt']" size="lg" />
      </a>
    </div>

    <Carousel
      ref="carousel"
      :per-page-custom="[
        [600, 3],
        [1000, 4],
      ]"
      autoplay
      class="gallery__thumbnails"
    >
      <Slide v-for="(item, i) in valueComp" :key="i" class="gallery__slide">
        <component
          :is="item.Component"
          :class="{ 'gallery__thumbnail--active': index === i }"
          class="gallery__thumbnail"
          :value="item"
          loop
          :mute="false"
          @click.native="goto(i)"
          hide-consent
        />
      </Slide>
    </Carousel>
    <div :id="scrollAnchor" class="scroll-anchor"></div>
  </div>
</template>

<script>
import { Slide, Carousel } from 'vue-carousel'
import MediumZoom from 'medium-zoom'
import { Types } from '@kvass/media-render'

export default {
  watch: {
    current: {
      handler: 'attach',
      immediate: true,
    },
  },
  props: {
    value: Array,
    scrollAnchor: {
      type: String,
      default: 'images',
    },
    size: {
      type: String,
      enum: ['cover', 'contain'],
      default: 'cover',
    },
    timeout: {
      type: Number,
      default: 6000,
    },
  },
  data() {
    return {
      index: 0,
      runner: null,
      playing: false,
      zoomer: MediumZoom([], {
        scrollOffset: 20,
        background: 'rgba(255, 255, 255, .8)',
      }),
    }
  },
  computed: {
    valueComp() {
      return this.value.map(item => {
        return {
          ...item,
          Component: this.getComponent(item.type),
        }
      })
    },
    current() {
      return this.valueComp[this.index] || {}
    },
    slideIndex() {
      return Math.floor(this.index / (this.$path('$refs.carousel.breakpointSlidesPerPage') || 1))
    },
    meta() {
      if (!this.valueComp.length) return
      return {
        next:
          this.index === this.valueComp.length - 1
            ? this.valueComp[0]
            : this.valueComp[this.index + 1],
        prev:
          this.index === 0
            ? this.valueComp[this.valueComp.length - 1]
            : this.valueComp[this.index - 1],
      }
    },
  },
  methods: {
    attach() {
      this.detach()
      this.$nextTick(() => this.zoomer.attach(this.$refs.current.$el))
    },

    detach() {
      if (!this.zoomer) return
      this.zoomer.detach()
    },

    getComponent(type) {
      switch (type) {
        case 'youtube':
          return Types.Youtube
        case 'vimeo':
          return Types.Vimeo
        default:
          return Types.Image
      }
    },
    goto(index) {
      this.playing = false
      this.index = index
      this.start()
    },
    ended() {
      this.playing = false
      this.next()
    },
    next() {
      if (this.playing) return
      this.index = this.index === this.valueComp.length - 1 ? 0 : this.index + 1
      this.start()
    },
    prev() {
      if (this.playing) return
      this.index = this.index === 0 ? this.valueComp.length - 1 : this.index - 1
      this.start()
    },
    start() {
      if (this.playing) return
      this.stop()
      this.runner = setTimeout(() => this.next(), this.timeout)
    },
    stop() {
      if (this.runner) clearTimeout(this.runner)
    },
    getValue(item) {
      if (['vimeo', 'youtube'].includes(item.type)) return item
      return [
        { pattern: /application\/pdf/, url: `${item.url}?convert=png`, type: item.type },
        { pattern: /.+/, url: item.url, type: item.type },
      ].find(e => e.pattern.test(item.type))
    },
  },
  mounted() {
    this.start()
  },
  beforeDestroy() {
    this.detach()
  },
  components: {
    Slide,
    Carousel,
  },
  metaInfo() {
    if (!this.meta || ['vimeo', 'youtube'].includes(this.meta.next.type)) return
    return {
      link: [
        {
          rel: 'preload',
          href: this.meta.next.url,
          as: 'image',
        },
      ],
    }
  },
}
</script>

<style lang="scss">
.gallery {
  position: relative;

  display: flex;
  flex-direction: column;

  &__main {
    z-index: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    @include aspect-ratio(16, 9);

    &-link {
      background: rgba(white, 0.5);
      position: absolute;
      bottom: 0;
      right: 0;
      padding: 1em;
    }

    &-image {
      flex-grow: 1;
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }

  &__caption {
    position: absolute;
    bottom: 0;
    font-weight: bold;
    background-color: rgba(white, 0.5);
    padding: 0.5rem 1rem;
    border-radius: 0 $border-radius 0 0;
  }

  &__thumbnail {
    height: 150px;
    cursor: pointer;
    position: relative;
    filter: grayscale(100%);
    transition: filter 150ms ease-out;

    &--active,
    &:hover {
      filter: grayscale(0);
    }
    iframe {
      pointer-events: none;
    }
  }

  &__main-image,
  &__thumbnail {
    height: 100%;
    object-position: center;
    border-radius: $border-radius;
    background-color: css-alpha('primary', 0.07);

    .gallery--size-cover & {
      object-fit: cover;
    }

    .gallery--size-contain & {
      object-fit: contain;
      padding: 2rem;
    }
  }

  &__thumbnails {
    margin: 0.5rem -0.5rem -0.5rem;
  }

  &__slide {
    padding: 0.5rem;
  }
}
</style>
