<template>
  <Lead
    class="container project-lead"
    :project="item.id"
    :reference="{ onModel: 'Project', ref: item.id }"
  >
    <template #title>
      {{ i18nCustom('leadLabel') }}
    </template>
    <template #actions>
      <ButtonComponent
        theme="secondary"
        tag="button"
        :icon="['far', 'arrow-down']"
        :label="i18nCustom('available-stock')"
        @click="goToFlatfinder"
      />
    </template>
  </Lead>
</template>

<script>
import { mapState } from 'vuex'
import Lead from '@kvass/lead'
import i18nCustom from '@/i18nCustom'

export default {
  computed: {
    ...mapState('Project', ['item']),
  },
  methods: {
    i18nCustom,
    goToFlatfinder() {
      let target = document.querySelector(
        '.scroll-anchor--type-anchor.scroll-anchor--value-storage-types',
      )
      if (!target) return
      target.scrollIntoView({ behavior: 'smooth' })
    },
  },
  components: {
    Lead,
  },
}
</script>

<style lang="scss">
.project-lead {
  max-width: 800px;
  margin: 0 auto;
  color: white;

  @include respond-below('phone') {
    h2 {
      font-size: 1em;
    }
  }
  .lead__title {
    max-width: 570px;

    margin: 0 auto;
    margin-bottom: 1.5rem;

    @include respond-below('phone') {
      font-size: 1em;
    }
  }

  .lead__form-actions {
    flex-wrap: nowrap;
    @include respond-below('phone') {
      gap: 1rem;
      .elder-button {
        font-size: 0.85em;
      }
    }
    //hide icon on lead sumbit button
    .elder-button:first-child {
      .elder-button {
        &__icon {
          display: none;
        }
        &__label {
          padding: var(--vue-elder-button-padding-y, 0.75em)
            var(--vue-elder-button-padding-x, 1.5em);
        }
      }
    }
  }

  .lead__form {
    background-color: transparent;
    grid-gap: 0.4rem;
  }
  .elder-input__field {
    background: rgba(252, 252, 252, 0.632);
  }
  .elder-input__label {
    color: white;
  }
  a {
    color: white;
  }

  .elder-input__element {
    padding: 0.7em;
  }
}
</style>
