<template>
  <div class="gallery">
    <div class="gallery__content">
      <Carousel
        ref="carousel"
        :per-page-custom="[
          [600, 2],
          [1000, 3],
        ]"
        autoplay
        loop
        :pagination-enabled="false"
        :space-padding="5"
        :autoplay-timeout="4000"
      >
        <Slide v-for="(item, i) in valueComp" :key="i" class="gallery__slide">
          <component
            :is="item.Component"
            :value="item"
            ref="items"
            loop
            :mute="false"
            class="gallery__item"
          />
        </Slide>
      </Carousel>
    </div>
    <div :id="scrollAnchor" class="scroll-anchor"></div>
  </div>
</template>

<script>
import MediumZoom from 'medium-zoom'
import { Types } from '@kvass/media-render'
import { Slide, Carousel } from 'vue-carousel'
import { mapState } from 'vuex'

export default {
  props: {
    scrollAnchor: {
      type: String,
      default: 'images',
    },
  },
  watch: {
    valueComp: {
      handler: 'attach',
      immediate: true,
    },
  },
  data() {
    return {
      zoomer: MediumZoom([], {
        scrollOffset: 20,
        background: 'rgba(255, 255, 255, .8)',
      }),
    }
  },
  computed: {
    ...mapState('Project', ['item']),
    items() {
      return this.$path('item.media.gallery') || []
    },
    valueComp() {
      return this.items.map(i => {
        return {
          ...i,
          Component: this.getComponent(i.type),
        }
      })
    },
  },

  methods: {
    getComponent(type) {
      switch (type) {
        case 'youtube':
          return Types.Youtube
        case 'vimeo':
          return Types.Vimeo
        default:
          return Types.Image
      }
    },
    attach() {
      this.detach()
      this.$nextTick(() => this.zoomer.attach(this.$refs.items.map(i => i.$el)))
    },
    detach() {
      if (!this.zoomer) return
      this.zoomer.detach()
    },
  },
  beforeDestroy() {
    this.detach()
  },
  components: {
    Slide,
    Carousel,
  },
}
</script>

<style lang="scss">
.gallery {
  padding: 5px 0;

  &__item {
    position: relative;
    height: 100%;
  }

  img {
    object-position: center;
  }

  .VueCarousel-dot--active {
    background-color: var(--primary) !important;
  }
}
</style>
