<template>
  <Flatfinder
    class="residential-flatfinder"
    :value="flatfinders"
    :suggested="suggestedFlatfinder"
    :properties="featuredProperties"
  />
</template>

<script>
import { mapGetters } from 'vuex'
import Flatfinder from '@kvass/flatfinder'

export default {
  computed: {
    ...mapGetters('Residential', ['flatfinders', 'suggestedFlatfinder', 'featuredProperties']),
  },
  components: {
    Flatfinder,
  },
}
</script>
