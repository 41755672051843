<template>
  <div class="project-info">
    <div class="project-info__title"></div>
    <div>
      <div class="project-info__description" v-html="description"></div>
    </div>
    <slot />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { RandomBetween } from '@/utils'

import BrochureComponent from '@/components/Brochure'

export default {
  computed: {
    ...mapState('Project', ['item']),
    brochure() {
      return this.$path('item.media.brochure') || []
    },
    description() {
      return (
        this.$path('item.description') ||
        new Array(35)
          .fill(0)
          .map(
            () =>
              `<span class="project-info__description-sample" style="width: ${RandomBetween(
                30,
                150,
              )}px"></span>`,
          )
          .join('')
      )
    },
  },
  methods: {
    gotoLead() {
      let target = document.querySelector(
        '.scroll-anchor--type-anchor.scroll-anchor--value-' + 'lead',
      )
      if (!target) return
      target.scrollIntoView({ behavior: 'smooth' })
    },
  },
  components: {
    BrochureComponent,
  },
}
</script>

<style lang="scss">
.project-info {
  &__description {
    line-height: 1.6;
    max-width: 460px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;

    &-sample {
      display: inline-block;
      height: 5px;
      background-color: $border-color;
      margin-right: 0.5em;
    }
    p:last-child {
      margin-bottom: 0;
    }
    margin-bottom: 3rem;
  }

  h2 {
    text-align: center;
    font-size: 3rem;
    text-decoration: underline;
    text-decoration-color: var(--custom-color);
    text-underline-offset: 0.5em;
    text-decoration-thickness: 2px;

    margin-bottom: 2rem;

    @include respond-below('phone') {
      font-size: 1.8rem;
      margin-bottom: 2rem;
    }
  }
}
</style>
