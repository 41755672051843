<template>
  <div v-if="items && items.length" class="storage-types-wrapper">
    <div class="storage-types">
      <div class="storage-types__items">
        <a
          v-for="(item, index) in items"
          :key="index"
          class="storage-types__item col"
          :href="item.link"
        >
          <h2 class="storage-types__label">{{ item.label }}</h2>
          <img
            v-if="item.cover"
            class="storage-types__image"
            :src="require(`@/assets/storage-types/${item.cover}`)"
            :class="{ 'storage-types__image--sold-out': item.status === 'sold-out' }"
          />
        </a>
      </div>
      <div class="storage-types__info">{{ i18nCustom('storage-types') }}</div>
    </div>
    <slot name="default" />
  </div>
</template>

<script>
import i18nCustom from '@/i18nCustom'
import { mapState } from 'vuex'
const availableItems = [
  { key: '48', path: 'lokalene/48-m/' },
  { key: '72', path: 'lokalene/72-m/' },
  { key: '96', path: 'lokalene/96-m/' },
  { key: '144', path: 'lokalene/144-m/' },
]

export default {
  computed: {
    ...mapState('Project', ['item']),
    enableTypes() {
      return this.$path('metadata.residentialProperties.grossArea', this.item)
    },
    stats() {
      return this.$path('stats', this.item)
    },
    items() {
      if (!this.enableTypes || !this.enableTypes.length) return []
      let content = this.enableTypes
        .map(c => {
          let match = Object.keys(this.stats).find(item => item.includes(c))
          if (!match) return {}
          let [size, key] = match.split('_')

          return {
            key,
            value: this.stats[match],
          }
        })
        .sort((a, b) => a.key - b.key)

      if (!content || !content.length) return []

      return content.map(i => {
        let match = availableItems.find(item => item.key.includes(i.key))
        if (!match) return {}

        let baseLink = 'https://www.mittegetlokale.no/'
        return {
          cover: `${match.key}.png`,
          label: `${i.key}m²`,
          link: `${baseLink}${match.path}`,
          status: i.value <= 0 ? 'sold-out' : 'sale',
        }
      })
    },
  },
  methods: {
    i18nCustom,
  },
}
</script>

<style lang="scss">
.storage-types {
  display: flex;
  flex-direction: column;
  justify-items: center;
  text-align: center;
  justify-content: center;
  cursor: pointer;

  &__items {
    display: flex;
    gap: 8rem;
    justify-content: center;
    flex-wrap: wrap;

    @include respond-below('phone') {
      gap: 3rem;
    }
  }

  &__item {
    align-items: center;
    justify-content: flex-start;
    gap: 3rem;
    text-decoration: none;

    @include respond-below('phone') {
      gap: 1rem;
      h2 {
        font-size: 1.1em;
      }
    }
  }

  &__label {
    font-size: 1.5em;
    font-weight: bold;
  }

  &__image {
    max-height: 200px;
    img {
      height: inherit;
    }
    &--sold-out {
      filter: grayscale(100%) brightness(50%) sepia(100%) hue-rotate(-45deg) saturate(200%);
    }

    @include respond-below('phone') {
      max-height: 120px;
    }
  }
  &__icon {
    &--sold-out {
      filter: grayscale(100%) brightness(50%) sepia(100%) hue-rotate(-45deg) saturate(200%);
    }
    svg {
      font-size: 9em;
    }
    @include respond-below('phone') {
      svg {
        font-size: 5em;
      }
    }
  }
  &__image,
  &__icon {
    &:hover {
      transform: scale(1.1, 1.1);
      transition: transform 1s ease-in-out;
    }
  }

  &__info {
    margin: 0 auto;
    margin-top: 2rem;
  }

  @include respond-below('phone') {
    h2 {
      margin: 0;
    }
  }
}
</style>
