<template>
  <div v-if="sellingpoints && sellingpoints.length" class="project-selling-points">
    <template v-for="(item, index) in sellingpoints">
      <div
        v-if="item"
        class="project-selling-points__item"
        v-html="item"
        :key="'sellingpoint--' + index"
      />
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { GetCustomField } from '@/utils'

export default {
  computed: {
    ...mapState('Project', ['item']),
    sellingpoints() {
      return GetCustomField('selling-points', this.item.customFields)
    },
  },
}
</script>

<style lang="scss">
.project-selling-points {
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  @include respond-below('phone') {
    grid-template-columns: 1fr;
  }
  &__item {
    padding: 0 3rem;
    margin: 3rem 0;

    &:nth-child(2n + 2) {
      border-left: 1px solid var(--custom-color);
    }

    @include respond-below('phone') {
      padding: 2rem 0;
      margin: 0;

      &:nth-child(n) {
        border-left: none;
        border-top: 1px solid var(--custom-color);
      }
      &:first-child {
        padding-top: 0;
        border: none;
      }
      &:last-child {
        padding-bottom: 0;
      }
    }
  }
}
</style>
