<template>
  <footer class="footer">
    <FooterContent
      v-if="customFooterContent.length"
      :value="customFooterContent"
      class="footer__custom-content container"
    />
    <div class="footer__container container">
      <div>
        <img :src="logo" width="150" :alt="project.name" />
        <div class="footer__copyright">
          &copy; {{ year }} - {{ $t('pageMadeBy') }}
          <a href="https://www.kvass.no" target="_blank">Kvass</a>
        </div>
        <div class="footer__links">
          <a href="/api/legal/privacy/tenant" target="_blank"
            >{{ $t('privacyPolicy') }} / {{ $t('cookiePolicy') }}</a
          >
          <a href="#" @click.prevent="editConsents">{{ $t('editConsents') }}</a>
        </div>
      </div>
      <div class="footer__menu">
        <strong class="footer__title">{{ $t('menu') }}</strong>
        <template v-for="(item, index) in navigation">
          <ActionComponent
            v-if="$path('action.name', item) !== $path('name', $route)"
            class="footer__menu-action"
            :class="{ 'footer__menu-action--empty': !item.action }"
            v-bind="item"
            :key="index"
          />
          <template v-if="item.items && item.items.length">
            <ActionComponent
              v-for="(nested, ni) in item.items"
              class="footer__menu-action footer__menu-action--indent"
              v-bind="nested"
              :key="index + '_' + ni"
            />
          </template>
        </template>
      </div>
    </div>
  </footer>
</template>

<script>
import ActionComponent from 'vue-elder-navigation/src/action'
import API from '@/api'
import FooterContent from '@kvass/template-footer-content'
import { GetCustomField, GetRouteBasedValue } from '@/utils'
import CM from '@/gdpr'

export default {
  props: {
    navigation: Array,
  },
  data() {
    return {
      year: new Date().getFullYear(),
    }
  },
  computed: {
    project: GetRouteBasedValue({
      Project: 'Project.item',
      Residential: 'Residential.item.project',
      default: {},
    }),
    customFooterContent() {
      if (!this.project) return
      return GetCustomField('footer-content', this.project.customFields) || []
    },
    logo() {
      return API.logoInverted
    },
  },
  methods: {
    editConsents() {
      CM.show()
    },
  },
  components: {
    ActionComponent,
    FooterContent,
  },
}
</script>

<style lang="scss">
.footer {
  padding: 6rem 0;
  background-color: var(--primary);
  color: white;

  &__container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 2rem;
  }

  &__copyright {
    margin-top: 2rem;

    a {
      font-weight: bold;
    }
  }

  &__menu {
    display: flex;
    flex-direction: column;

    a {
      & + a {
        margin-top: 0.5rem;
      }
    }
  }

  &__menu {
    &-action {
      padding: 0;
      background-color: transparent !important;
      color: inherit;
      font-weight: inherit;

      &--indent {
        margin-left: 0.5rem;
      }

      & + & {
        margin-top: 0.5rem;
      }
    }
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  &__custom-content {
    margin-bottom: 4rem;
    a {
      text-decoration: underline;
    }
  }

  &__title {
    display: block;
    opacity: 0.5;
    margin-bottom: 1rem;
  }

  &__links {
    display: flex;
    flex-direction: column;
    margin-top: 0.5rem;
    gap: 0.5rem;
  }

  .user-profile {
    display: none;
  }
}
</style>
