import API from '@/api'
import { path } from 'ramda'

const state = {
  item: {},
  promises: {
    fetch: null,
  },
}

const mutations = {
  set(state, payload) {
    state.item = payload
  },
  setPromise(state, { key, promise }) {
    state.promises[key] = promise
  },
}

const actions = {
  fetch({ commit, state }, payload) {
    commit('set', {})
    let promise = API.getResidential(payload).then(data => {
      commit('set', data)
      return data
    })
    commit('setPromise', { key: 'fetch', promise })
    return promise
  },
}

const getters = {
  flatfinders: state => {
    return path(['project', 'flatfinders'], state.item)
  },
  suggestedFlatfinder: state => {
    return path(['flatfinders', '0'], state.item)
  },
  featuredProperties: state => {
    return path(
      ['project', 'metadata', 'siteSettings', 'featuredResidentialProperties'],
      state.item,
    )
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
