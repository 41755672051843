<template>
  <Carousel :value="value" v-bind="$attrs" />
</template>

<script>
import Carousel from '@kvass/carousel'

export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    Carousel,
  },
}
</script>

<style lang="scss">
.carousel {
  &__slide {
    background-color: $light-grey;
  }
}
</style>
