import { library } from '@fortawesome/fontawesome-svg-core'

/* Solid icons */
import { faTh as fasTh } from '@fortawesome/pro-solid-svg-icons/faTh'
import { faExclamationCircle as fasExclamationCircle } from '@fortawesome/pro-solid-svg-icons/faExclamationCircle'

library.add(fasTh, fasExclamationCircle)

/* Regular icons */
import { faArrowDown as farArrowDown } from '@fortawesome/pro-regular-svg-icons/faArrowDown'
import { faArrowUp as farArrowUp } from '@fortawesome/pro-regular-svg-icons/faArrowUp'
import { faArrowRight as farArrowRight } from '@fortawesome/pro-regular-svg-icons/faArrowRight'
import { faArrowLeft as farArrowLeft } from '@fortawesome/pro-regular-svg-icons/faArrowLeft'
import { faAngleDown as farAngleDown } from '@fortawesome/pro-regular-svg-icons/faAngleDown'
import { faPen as farPen } from '@fortawesome/pro-regular-svg-icons/faPen'
import { faExternalLinkAlt as farExternalLinkAlt } from '@fortawesome/pro-regular-svg-icons/faExternalLinkAlt'

library.add(
  farArrowDown,
  farArrowRight,
  farArrowLeft,
  farPen,
  farArrowUp,
  farAngleDown,
  farExternalLinkAlt,
)

/* Light icons */
import { faExclamationCircle as falExclamationCircle } from '@fortawesome/pro-light-svg-icons/faExclamationCircle'
import { faCalendar as falCalendar } from '@fortawesome/pro-light-svg-icons/faCalendar'
import { faEnvelope as falEnvelope } from '@fortawesome/pro-light-svg-icons/faEnvelope'
import { faMobile as falMobile } from '@fortawesome/pro-light-svg-icons/faMobile'
import { faPlus as falPlus } from '@fortawesome/pro-light-svg-icons/faPlus'

library.add(falExclamationCircle, falCalendar, falEnvelope, falMobile, falPlus)

/* Brand icons */
// import { faFacebook as fabFacebook } from '@fortawesome/free-brands-svg-icons/faFacebook'

// library.add(fabFacebook, fabLinkedIn, fabInstagram)

/* Duotone icons */
import { faSyncAlt as fadSyncAlt } from '@fortawesome/pro-duotone-svg-icons/faSyncAlt'
import { faAngleDown as fadAngleDown } from '@fortawesome/pro-duotone-svg-icons/faAngleDown'
import { faSpinnerThird as fadSpinnerThird } from '@fortawesome/pro-duotone-svg-icons/faSpinnerThird'
import { faFilePdf as fadFilePdf } from '@fortawesome/pro-duotone-svg-icons/faFilePdf'
import { faFileAlt as fadFileAlt } from '@fortawesome/pro-duotone-svg-icons/faFileAlt'
import { faFileImage as fadFileImage } from '@fortawesome/pro-duotone-svg-icons/faFileImage'
import { faWarehouse as fadWarehouse } from '@fortawesome/pro-duotone-svg-icons/faWarehouse'
import { faRulerTriangle as fadRulerTriangle } from '@fortawesome/pro-duotone-svg-icons/faRulerTriangle'
import { faTag as fadTag } from '@fortawesome/pro-duotone-svg-icons/faTag'
import { faSortNumericUp as fadSortNumericUp } from '@fortawesome/pro-duotone-svg-icons/faSortNumericUp'
import { faToggleOn as fadToggleOn } from '@fortawesome/pro-duotone-svg-icons/faToggleOn'
import { faCheckSquare as fadCheckSquare } from '@fortawesome/pro-duotone-svg-icons/faCheckSquare'

library.add(
  fadSyncAlt,
  fadAngleDown,
  fadSpinnerThird,
  fadFilePdf,
  fadFileAlt,
  fadFileImage,
  fadWarehouse,
  fadRulerTriangle,
  fadTag,
  fadSortNumericUp,
  fadToggleOn,
  fadCheckSquare,
)
