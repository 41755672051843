<template>
  <div v-if="items.length" class="project-roles">
    <div class="project-roles__list">
      <div class="project-roles__item" v-for="(item, index) in items" :key="index">
        <div
          class="project-roles__avatar"
          :style="{ backgroundImage: `url(${item.avatar})` }"
        ></div>
        <div class="project-roles__content">
          <div class="project-roles__name">{{ item.name }}</div>
          <div v-if="item.title" class="project-roles__user-title">{{ item.title }}</div>
          <a v-if="item.email" class="project-roles__email" :href="`mailto:${item.email}`"
            ><FontAwesomeIcon :icon="['fal', 'envelope']" />{{ $t('sendEmail') }}</a
          >
          <a v-if="item.phone" class="project-roles__phone" :href="`tel:${item.phone}`"
            ><FontAwesomeIcon :icon="['fal', 'mobile']" />{{ item.phone }}</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('Project', ['item']),
    items() {
      return this.$path('item.roles.salesman') || []
    },
  },
}
</script>
<style lang="scss">
.project-roles {
  &__list {
    display: flex;
    gap: 4rem;
    justify-content: center;
    @include respond-below('phone') {
      gap: 3rem;
      flex-direction: column;
    }
  }

  &__title {
    margin-bottom: 1.5rem;
    @include respond-below('phone') {
      text-align: center;
    }
  }

  &__avatar {
    $size: 130px;

    height: $size;
    width: $size;
    border-radius: 50%;
    background-size: cover;
    background-position: center;

    margin-right: 1rem;

    flex-shrink: 0;
    @include respond-below('phone') {
      height: calc(#{$size}/ 1.3);
      width: calc(#{$size}/ 1.3);
    }
  }

  &__subtitle {
    color: rgba($dark, 0.6);
  }

  &__user-title {
    margin: 2px 0;
  }
  &__name {
    margin-top: 1.5rem;
  }

  &__item {
    background: transparent;
    background-color: white;

    display: flex;
    flex-direction: column;
    align-items: center;
    align-items: center;

    @include respond-below('phone') {
      text-align: center;
      .roles {
        &__name {
          margin-top: 10px;
        }
        &__avatar {
          $size: 80px;
          width: $size;
          height: $size;
        }

        &__email,
        &__phone {
          display: block;
          margin-top: 3px;
        }
      }
    }
  }

  &__name {
    font-weight: bold;
    font-size: 1.1em;
  }

  &__email,
  &__phone {
    opacity: 0.6;
    text-decoration: none;
    white-space: nowrap;

    margin-right: 10px;

    svg {
      margin-right: 7px;
    }
  }
}
</style>
