const items = {
  'storage-types': {
    nb: 'Bildene illustrerer seksjoner hvor det er gjort tilvalg. Klikk på dem for å se mer.',
  },
  leadLabel: {
    nb: 'Er du interessert i å kjøpe en lagerseksjon? Meld interesse i dag.',
    en: 'Are you interested in buying a stock section? Register interest today.',
    sv: 'Är du intresserad av att köpa en aktiesektion? Anmäl intresse idag.',
    da: 'Er du interesseret i at købe en aktiesektion? Tilmeld interesse i dag.',
  },
  completion: {
    nb: 'Forventet ferdigstillelse',
    en: 'Expected completion',
    sv: 'Förväntat färdigställande',
    da: 'Forventet færdiggørelse.',
  },
  mapInfo: {
    nb: 'Bruk to fingre for å navigere kart',
    en: 'Use two fingers to navigate the map',
    sv: 'Använd två fingrar för att navigera på kartor',
    da: 'Brug to fingre til at navigere i kort',
  },
  progressBar: {
    nb: 'Prosjektets fremdrift',
    en: 'Project progress',
    sv: 'Projektframsteg',
    da: 'Projektfremgang',
  },
  location: {
    nb: 'Beliggenhet',
    en: 'Location',
    sv: 'Plats',
    da: 'Beliggenhed',
  },
  news: {
    nb: 'Nyheter',
    en: 'News',
    sv: 'Nyheter',
    da: 'Nyheder',
  },
  'available-stock': {
    nb: 'Se ledige lager',
    en: 'See available stock',
    sv: 'Se tillgängligt lager',
    da: 'Se tilgængelig lager',
  },
}

export default function(key) {
  return items[key][document.documentElement.lang]
}
