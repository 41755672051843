<template>
  <Loader :value="promises.fetch" theme="default">
    <div class="project-page">
      <div class="hero__wrapper section__hero">
        <ScrollAnchor value="lead" offset="-8rem" :delay="750" />
        <Hero class="project-page__hero">
          <template #overlay>
            <Lead class="project-page__lead" />
          </template>
        </Hero>
        <Properties />
      </div>
      <section class="section section__info">
        <div class="container">
          <Info> <ProgressBar /> </Info>

          <div class="row center middle">
            <BrochureComponent
              :value="$path('media.brochure', item) || []"
              class="triggers__brochure"
            />

            <ButtonComponent
              theme="primary"
              :icon="['far', 'arrow-up']"
              label="Ta kontakt"
              @click="gotoLead"
            />
          </div>
          <Roles />
          <Map :zoom="9.5" />
        </div>
      </section>

      <section v-if="hasResidentials" class="section__storage-types ">
        <ScrollAnchor value="storage-types" offset="-12rem" :delay="750" />
        <div class="container">
          <StorageTypes />
        </div>
      </section>

      <section v-if="view360.id" class="section__view-360">
        <ScrollAnchor value="360-view" offset="-12rem" :delay="750" />
        <div class="container">
          <div class="view-360__content-above" v-html="view360['content-above']"></div>
          <Embed :value="view360.id" />
          <div class="view-360__content-below" v-html="view360['content-below']"></div>
        </div>
      </section>

      <section v-if="$path('flatfinders.length', item)" class="section section__flatfinder">
        <ScrollAnchor value="flatfinder" offset="-8rem" :delay="750" />
        <div class="container">
          <Flatfinder />
        </div>
      </section>
      <section v-if="$path('stats.total', item)" class="section section__residentials">
        <div class="container">
          <ScrollAnchor value="residentials" offset="-8rem" :delay="750" />
          <Residentials />
        </div>
      </section>
      <section v-if="showResidentialTemplates" class="section section__residential-templates">
        <div class="container">
          <ScrollAnchor value="residential-templates" offset="-8rem" :delay="750" />
          <ResidentialTemplates />
        </div>
      </section>
      <section class="section section__attachments" v-if="$path('media.attachments.length', item)">
        <div class="container">
          <ScrollAnchor value="attachments" offset="-8rem" :delay="750" />
          <Attachments />
        </div>
      </section>

      <section class="section section__selling-points" v-if="$path('customFields', item)">
        <div class="container">
          <SellingPoints />
        </div>
      </section>

      <section class="section__additional-info">
        <div class="container">
          <ScrollAnchor value="additional-info" offset="-8rem" :delay="750" />
          <AdditionalInfo />
        </div>
      </section>

      <section v-if="$path('media.gallery.length', item)" class="section section__images">
        <ScrollAnchor value="gallery" offset="-8rem" :delay="750" />
        <Gallery />
      </section>

      <section class="section section__map section--no-p">
        <ScrollAnchor value="map" offset="-8rem" :delay="750" />
        <Map />
      </section>
    </div>
  </Loader>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ProjectComponents from '@/components/Project'
import Residentials from '@/components/Residentials'

import ScrollAnchor from '@kvass/scroll-anchor'
import BrowserApiMixin from '@/mixins/browser-api'
import { GetCustomField } from '@/utils'
import i18nCustom from '@/i18nCustom'
import BrochureComponent from '@/components/Brochure'

export default {
  mixins: [BrowserApiMixin('project')],
  computed: {
    ...mapState('Project', ['promises', 'item']),
    showOpenhouse() {
      if (!this.item.id) return false
      if (this.$path('item.openhouses.length')) return true
      return !this.$path('item.metadata.siteSettings.hideNoOpenhouse')
    },
    hasResidentials() {
      return KvassConfig.get('stats.total') > 0
    },
    showResidentialTemplates() {
      if (!this.item) return false
      return GetCustomField('residential-templates', this.item.customFields)
    },
    view360() {
      if (!this.item) return false
      return GetCustomField('360-view', this.item.customFields) || {}
    },
  },

  methods: {
    ...mapActions('Project', ['fetch']),
    GetCustomField,
    i18nCustom,
    gotoLead() {
      let target = document.querySelector(
        '.scroll-anchor--type-anchor.scroll-anchor--value-' + 'lead',
      )
      if (!target) return
      target.scrollIntoView({ behavior: 'smooth' })
    },
  },

  created() {
    this.fetch()
  },
  metaInfo() {
    return {
      title: this.$t('home'),
    }
  },
  components: {
    ...ProjectComponents,
    Residentials,
    ScrollAnchor,
    BrochureComponent,
  },
}
</script>

<style lang="scss">
.project-page {
  display: flex;
  flex-direction: column;

  @for $i from 1 through 15 {
    & > *:nth-child(#{$i}) {
      order: $i * 10;
    }
  }

  .section {
    &--no-pb {
      padding-bottom: 0;
    }

    &--no-p {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .section__selling-points {
    @include respond-below('phone') {
    }
  }
  .section__images {
    padding-bottom: 0;
  }
  .section__storage-types {
    order: 21;
    padding-bottom: 4rem;
  }
  .section__view-360 {
    order: 22;

    .view-360 {
      &__content-above {
        text-align: center;
      }
    }
  }

  .section__info {
    .container {
      max-width: $projectInfoWidth;
      $responsiveGap: 4rem;
      display: flex;
      flex-direction: column;
      gap: 5rem 0;
      justify-content: center;
      .project-map .vue-make-lazy {
        height: 350px;
      }
      .mgl-map {
        height: 100%;
        min-height: initial;
      }
      & > *:nth-child(2) {
        gap: 3rem;
        flex-wrap: wrap;
      }

      @include respond-below('phone') {
        gap: $responsiveGap;

        & > *:nth-child(2) {
          font-size: 0.75em;
          gap: 1rem;
        }

        & > *:nth-child(4) {
          margin: 0 -2rem;
        }
      }
    }
  }
}
</style>
