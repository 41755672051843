<template>
  <div v-if="value" class="map">
    <LazyLoad>
      <MapComponent :center="$path('location.coordinates', value)" v-bind="$attrs" />
    </LazyLoad>
    <div class="map__address" v-if="value.formatted">
      {{ value.formatted }}
    </div>
    <div id="map" class="scroll-anchor"></div>
  </div>
</template>

<script>
import MapComponent from './Map'
import LazyLoad from 'vue-make-lazy'

export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    MapComponent,
    LazyLoad,
  },
}
</script>

<style lang="scss">
@import '@/styles/main';

.map {
  position: relative;
  text-align: center;
  background: hsla(var(--primary-h), var(--primary-s), var(--primary-l), 0.9);
  border: none;
  color: white;

  &__address {
    padding: 0.5rem;
  }
}
</style>
