<template>
  <Flatfinder
    class="project-flatfinder"
    :value="item.flatfinders"
    :properties="$path('metadata.siteSettings.featuredResidentialProperties', item)"
  />
</template>

<script>
import { mapState } from 'vuex'
import Flatfinder from '@kvass/flatfinder'

export default {
  computed: {
    ...mapState('Project', ['item']),
  },
  components: {
    Flatfinder,
  },
}
</script>

<style lang="scss">
.flatfinder-leaflet-v1__aspect,
.flatfinder-leaflet-v1 .leaflet-container {
  background: transparent !important;
  width: 100%;
  max-width: inherit !important;
}
.flatfinder__container {
  padding: 0 !important;
}
</style>
