import isMobilePhone from 'validator/lib/isMobilePhone'

export default (Validator) => {
  Validator.register(
    'phone',
    function (value = '') {
      return isMobilePhone(value.toString().replace(/\s/g, ''))
    },
    ':attribute er ikke et gyldig nummer',
  )
}
