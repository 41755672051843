<template>
  <DropdownComponent v-if="value.length">
    <ButtonComponent
      theme="primary"
      :icon="['far', 'arrow-down']"
      :label="actionLabel"
      @click="hasMany ? () => {} : download(value[0])"
    />
    <template v-if="hasMany" #dropdown>
      <ButtonComponent
        v-for="(item, index) in value"
        :key="index"
        :icon="['fad', 'file-pdf']"
        :label="item.name"
        @click="download(item)"
      />
    </template>
  </DropdownComponent>
</template>

<script>
import { DropdownComponent } from 'vue-elder-dropdown'
import { EventBus as AssetBus } from '@kvass/asset-manager'
import { GetRouteBasedValue, Capitalize } from '@/utils'

export default {
  props: {
    value: Array,
  },
  computed: {
    hasMany() {
      return this.value.length > 1
    },
    brochureStrategy: GetRouteBasedValue({
      Project: 'Project.item.metadata.siteSettings.brochureDownloadStrategy',
      Residential: 'Residential.item.project.metadata.siteSettings.brochureDownloadStrategy',
      default: 'direct',
    }),
    actionLabel() {
      return this.$t('assetManagerTitleBrochure' + Capitalize(this.brochureStrategy))
    },
  },
  methods: {
    download(item) {
      AssetBus.emit('download', {
        type: 'brochure',
        strategy: this.brochureStrategy,
        asset: item,
      })
    },
  },
  components: {
    DropdownComponent,
  },
}
</script>
