<template>
  <div class="project-embed">
    <iframe
      :src="`//youriguide.com/embed/${value}?bgcolor=FFFFFF`"
      scrolling="no"
      frameborder="0"
      allowfullscreen
    ></iframe>
  </div>
</template>

<script>
export default {
  props: {
    value: String,
  },
}
</script>

<style lang="scss">
.project-embed {
  display: block;
  position: relative;
  padding: 0 0 57% 0;
  overflow: hidden;
  height: 0;
  width: 100%;
  iframe {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}
</style>
